import { httpService } from '../httpService';
import moment from 'moment-timezone';
import jwt_decode from 'jwt-decode';
import sign from 'jwt-encode';

const STORAGE_LOGGEDIN_MEMBER = 'user'
const STORAGE_TEST = 'question'
const STORAGE_SURVEY = 'survey'
const STORAGE_EXAM = 'exam'

export const userService = {
  signup,
  login,
  logout,
  getStorage,
  clearStorage,
  getParticipant,
  updParticipant,
  changeEmail,
  updLoged,
  caculate,
  viewLecture,
  getNextRow,
  updLecture,
  insMemo,
  insHis,
  insSurHis,
  getTestPar,
  insTestPar,
  updTestPar,
  getLecture,
  getQuestion,
  getSurvey,
  getTest,
  getTextbook,
  getTextbooks,
  getSupplementary,
  getSupplementaries,
  getLoginUser,
  getEmails,
  getCreatedinUser,
  sendToEmail,
  checkExpried,
  renew,
  getUserByEmail,
  deactivateParticipant,
  deleteRelation
}

async function login(userCred) {
  const user = await httpService.post('authenticate', userCred)
  if (user !== null && user.status === 200) {
    setStorage(STORAGE_LOGGEDIN_MEMBER, user);
  }
  return user;
}

async function sendToEmail(emailCred, type) {
  if (type === 0) {
    return await httpService.post('check-email', emailCred)
  } else if (type === 1) {
    return await httpService.post('send-ok-email', emailCred)
  } else if (type === 2) {
    return await httpService.post('send-reissue-password-email', emailCred)
  } else if (type === 3) {
    return await httpService.post('send-account-registration-email', emailCred)
  } else if (type === 4) {
    return await httpService.post('send-deactivate-email', emailCred)
  }
  else {
    return null;
  }
}

async function getParticipant() {
  let loginU = getLoginUser();
  if (loginU === null) {
    return null;
  }
  let token = 'Bearer ' + loginU.token;
  let participant_id = loginU.data.id;
  return await httpService.get(`getParticipant/${participant_id}`, null, token)
}

async function updParticipant(participant_id, body) {
  let loginU =  getLoginUser();
  if (loginU === null) {
    return null;
  }
  let token = 'Bearer ' + loginU.token;
  return await httpService.put(`updParticipant/${participant_id}`, body, token)
}

async function changeEmail(body) {
  return await httpService.put(`changeEmail`, body)
}

async function updLoged(participant_id, body) {
  return await httpService.put(`updLoged/${participant_id}`, body)
}

async function caculate(body) {
  let loginU = getLoginUser();
  if (loginU === null) {
    return null;
  }
  let token = 'Bearer ' + loginU.token;
  return await httpService.post(`caculate`, body, token)
}

async function insHis(body) {
  let loginU = getLoginUser();
  if (loginU === null) {
    return null;
  }
  let token = 'Bearer ' + loginU.token;
  let participant_id = loginU.data.id;
  return await httpService.post(`insHis/${participant_id}`, body, token)
}

async function insSurHis(body) {
  let loginU = getLoginUser();
  if (loginU === null) {
    return null;
  }
  let token = 'Bearer ' + loginU.token;
  let participant_id = loginU.data.id;
  return await httpService.post(`insSurHis/${participant_id}`, body, token)
}

async function insMemo(lecture_id, body) {
  let loginU = getLoginUser();
  if (loginU === null) {
    return null;
  }
  let token = 'Bearer ' + loginU.token;
  let participant_id = loginU.data.id;
  return await httpService.post(`insMemo/${participant_id}/${lecture_id}`, body, token)
}

async function insTestPar(test_id, body) {
  let loginU = getLoginUser();
  if (loginU === null) {
    return null;
  }
  let token = 'Bearer ' + loginU.token;
  let participant_id = loginU.data.id;
  return await httpService.post(`insTestPar/${participant_id}/${test_id}`, body, token)
}

async function updTestPar(test_id, created_at, body) {
  let loginU = getLoginUser();
  if (loginU === null) {
    return null;
  }
  let token = 'Bearer ' + loginU.token;
  let participant_id = loginU.data.id;
  return await httpService.put(`updTestPar/${participant_id}/${test_id}/${created_at}`, body, token)
}

async function getTestPar(test_id) {
  let loginU = getLoginUser();
  if (loginU === null) {
    return null;
  }
  let token = 'Bearer ' + loginU.token;
  let participant_id = loginU.data.id;
  const testpar = await httpService.get(`getTestPar/${participant_id}/${test_id}`, null, token)
  if (testpar !== null && testpar.status === 200) {
    setStorage(STORAGE_EXAM, testpar);
  }
  return testpar;
}

async function updLecture(lecture_id, body) {
  let loginU = getLoginUser();
  if (loginU === null) {
    return null;
  }
  let token = 'Bearer ' + loginU.token;
  let participant_id = loginU.data.id;
  return await httpService.put(`updLecture/${participant_id}/${lecture_id}`, body, token)
}

async function viewLecture(lecture_id) {
  let loginU = getLoginUser();
  if (loginU === null) {
    return null;
  }
  let token = 'Bearer ' + loginU.token;
  let participant_id = loginU.data.id;
  return await httpService.get(`viewLecture/${participant_id}/${lecture_id}`, null, token)
}

async function getNextRow(lecture_id) {
  let loginU = getLoginUser();
  if (loginU === null) {
    return null;
  }
  let token = 'Bearer ' + loginU.token;
  let participant_id = loginU.data.id;
  return await httpService.get(`getNextRow/${participant_id}/${lecture_id}`, null, token)
}

async function getLecture() {
  let loginU = getLoginUser();
  if (loginU === null) {
    return null;
  }
  let token = 'Bearer ' + loginU.token;
  let participant_id = loginU.data.id;
  return await httpService.get(`getLecture/${participant_id}`, null, token)
}

async function getTest() {
  let loginU = getLoginUser();
  if (loginU === null) {
    return null;
  }
  let token = 'Bearer ' + loginU.token;
  let participant_id = loginU.data.id;
  return await httpService.get(`getTest/${participant_id}`, null, token)
}

async function getQuestion(test_id) {
  let loginU = getLoginUser();
  if (loginU === null) {
    return null;
  }
  let token = 'Bearer ' + loginU.token;
  let question = await httpService.get(`getQuestion/${test_id}`, null, token)
  if (question !== null && question.status === 200) {
    setStorage(STORAGE_TEST, question);
  }
  return question;
}

async function getSurvey(subject) {
  let loginU = getLoginUser();
  if (loginU === null) {
    return null;
  }
  let token = 'Bearer ' + loginU.token;
  let survey = await httpService.get(`getSurvey/${subject}`, null, token)
  if (survey !== null && survey.status === 200) {
    setStorage(STORAGE_SURVEY, survey);
  }
  return survey;
}

async function getTextbook(id) {
  let loginU = getLoginUser();
  if (loginU === null) {
    return null;
  }
  let token = 'Bearer ' + loginU.token;
  return await httpService.get(`getTestPar/${id}`, null, token)
}

async function getTextbooks() {
  let loginU = getLoginUser();
  if (loginU === null) {
    return null;
  }
  let token = 'Bearer ' + loginU.token;
  return await httpService.get(`getTextbooks`, null, token)
}

async function getSupplementary(id) {
  let loginU = getLoginUser();
  if (loginU === null) {
    return null;
  }
  let token = 'Bearer ' + loginU.token;
  return await httpService.get(`getSupplementary/${id}`, null, token)
}

async function getSupplementaries() {
  let loginU = getLoginUser();
  if (loginU === null) {
    return null;
  }
  let token = 'Bearer ' + loginU.token;
  return await httpService.get(`getSupplementaries`, null, token)
}

async function signup(userCred) {
  const user1 = await httpService.post('signup', userCred)
  if (user1 !== null && user1.status === 200) {
    setStorage(userCred.email, user1)
  }
  return user1;
}

async function logout() {
  localStorage.removeItem(STORAGE_LOGGEDIN_MEMBER)
  sessionStorage.removeItem(STORAGE_LOGGEDIN_MEMBER)
  localStorage.removeItem(STORAGE_EXAM)
  sessionStorage.removeItem(STORAGE_EXAM)
  localStorage.removeItem(STORAGE_SURVEY)
  sessionStorage.removeItem(STORAGE_SURVEY)
  localStorage.removeItem(STORAGE_TEST)
  sessionStorage.removeItem(STORAGE_TEST)
}

function getStorage(key) {
  return JSON.parse(localStorage.getItem(key))
}

async function clearStorage(key) {
  localStorage.removeItem(key)
  sessionStorage.removeItem(key)
}

function setStorage(key, vv) {
  localStorage.setItem(key, JSON.stringify(vv))
}

function getLoginUser() {
  return JSON.parse(localStorage.getItem(STORAGE_LOGGEDIN_MEMBER))
}

function checkExpried(userLogin) {
  if (userLogin === null) {
    return false
  }
  let decodedToken = jwt_decode(JSON.stringify(userLogin && userLogin.token));
  let currentDate = new Date();
  if (decodedToken.exp * 1000 < currentDate.getTime()) {
    return true;
  } else {
    return false;
  }
}

function renew (dt) {
  let userLogin = getLoginUser();
  if (userLogin !== null && userLogin.token !== null) {
    // let decodedToken = jwt_decode(JSON.stringify(userLogin.token));
    let currentDate = new Date();
    // let startdate = moment(currentDate).format("YYYY-MM-DD HH:mm:ss");
    let expDate = moment(currentDate).add(dt, 'seconds').format("YYYY-MM-DD HH:mm:ss")
    // let expDate1 = moment(currentDate).add(hh, 'hours').add(mm, 'minutes').add(1800, 'seconds').tz("Asia/Tokyo").format("YYYY-MM-DD HH:mm:ss");
    let encodedToken = sign({ data: JSON.stringify(userLogin.data), iat: currentDate.getTime() / 1000, exp: new Date(Date.parse(expDate)).getTime() / 1000 }, process.env.REACT_APP_SECRET_KEY);
    userLogin.token = encodedToken
    // userLogin.is_login = uu.data.is_login
    // userLogin.data.is_login = uu.data.is_login
    setStorage(STORAGE_LOGGEDIN_MEMBER, userLogin);
  }
}

async function getUserByEmail (userLogin) {
  return await httpService.post('get-user-by-email', {"email": userLogin.data.email});
}

function getCreatedinUser(email) {
  return JSON.parse(localStorage.getItem(email))
}

async function getEmails() {
  let loginU = getLoginUser();
  if (loginU === null) {
    return null;
  }
  let token = 'Bearer ' + loginU.token;
  return await httpService.get(`getEmails`, null, token);
}

async function deactivateParticipant(participant_id) {
  let loginU =  getLoginUser();
  if (loginU === null) {
    return null;
  }
  let token = 'Bearer ' + loginU.token;
  return await httpService.put(`deactivated/${participant_id}`, token)
}

async function deleteRelation(participant_id) {
  let loginU = getLoginUser();
  if (loginU === null) {
    return null;
  }
  let token = 'Bearer ' + loginU.token;
  return await httpService.put(`deleteRelation/${participant_id}`, token)
}