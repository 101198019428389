import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux'
import { useMediaQuery } from "react-responsive";
import { useHistory, useLocation, Route, Redirect } from 'react-router-dom'
import { logout, changeEmail } from '../store/actions/userActions'
import { userService } from '../services/user/userService'
import CryptoJS from "crypto-js";
import logo from "../assets/imgs/logo.png";
import logom from "../assets/imgs/logom.png";
import loadingGif from "../assets/imgs/loading-gif.gif";
import styles from '../figma/RegistrationForm/RegistrationForm.module.css';

function PublicRoute({ component: Component, ...rest }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const isDesktop = useMediaQuery({ minWidth: 992 });

  const userLogin = userService.getLoginUser()
  const checkExpried = userService.checkExpried(userLogin)


  const [regis, setRegis] = useState(false);
  const [expried] = useState(false);
  const [done] = useState(false);
  const [spinner, setSpinner] = useState(true);

  const decryptAES = (encryptedBase64) => {
    var key = process.env.REACT_APP_KEY;
    return CryptoJS.AES.decrypt(encryptedBase64, key).toString(
      CryptoJS.enc.Utf8
    );
  }

  const checkExpriedEmail = (dt) => {
    let current = new Date().getTime();
    let datetime = Number(dt);
    if (current - datetime > 24 * 60 * 60 * 1000) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    if (location.pathname === "/signup") {
      dispatch(logout()).then(() => {
        history.push("/signup" + location.search);
      });
    }
    if (location.pathname === "/email") {
      dispatch(logout());
      let em1 = location && location?.search.split("?newEmail=")[1].split("&oldEmail=")[0];
      let em2 = location && location?.search.split("&oldEmail=")[1].split("&datetime=")[0];
      let dt1 = location && location?.search.split("&datetime=")[1]
      console.log(em1)
      console.log(em2)
      console.log(dt1)
      // history.push({
      //   pathname: "/emailchange",
      //   state: { from: "emailchange", em1: em1, em2: em2, dt: dt1 } //pass state to testcard page
      // });
      let newEmail = decryptAES(em1);
      let oldEmail = decryptAES(em2);
      let dt = decryptAES(dt1);
      let exp = checkExpriedEmail(dt);
      if (!exp && newEmail !== oldEmail) {
        dispatch(changeEmail({ newEmail: newEmail, oldEmail: oldEmail })).then((res) => {
          if (res && res.status === 200) {
            setSpinner(false);
            history.push("signin")
          }
          if (res && res.status === 403) {
            setRegis(true)
            setSpinner(false)
          }
        });
      }
    }
  }, [dispatch])

  if (!spinner && regis && location.pathname === "/email") {
    return (
      <main className="loginPage">
        <section className="background">
          <div className="loginContainer">
            <div className="formWrapper">
              <div className={styles.formContent}>
                <img src={isDesktop ? logo : logom} alt="serviceLogo" className={isDesktop ? styles.serviceLogo : styles.serviceLogom} onClick={(ev) => { ev.preventDefault(); history.push("/"); }} />
                <div className={styles.instructions3}>メールアドレスが変更されました</div>
              </div>
            </div>
          </div>
        </section>
      </main>
    );
  } else if (!spinner && expried && location.pathname === "/email") {
    return (
      <main className="loginPage">
        <section className="background">
          <div className="loginContainer">
            <div className="formWrapper">
              <div className={styles.formContent}>
                <img src={isDesktop ? logo : logom} alt="serviceLogo" className={isDesktop ? styles.serviceLogo : styles.serviceLogom} onClick={(ev) => { ev.preventDefault(); history.push("/"); }} />
                <div className={styles.instructions3}>URLの有効期限が切れました</div>
              </div>
            </div>
          </div>
        </section>
      </main>
    );
  } else if (!spinner && done && location.pathname === "/email") {
    return (
      <main className="loginPage">
        <section className="background">
          <div className="loginContainer">
            <div className="formWrapper">
              <div className={styles.formContent}>
                <img src={isDesktop ? logo : logom} alt="serviceLogo" className={isDesktop ? styles.serviceLogo : styles.serviceLogom} onClick={(ev) => { ev.preventDefault(); history.push("/"); }} />
              </div>
            </div>
          </div>
        </section>
      </main>
    );
  } else if (spinner && location.pathname === "/email") {
    return (
      <main className="loginPage">
        <section className="background">
          <div className="loginContainer">
            <div className="formWrapper">
              <form className={styles.formContent}>
                {spinner && (
                  <span className={styles.gifContainer}>
                    <img className={styles.loadingGif} src={loadingGif} alt="ok" />
                  </span>
                )}
              </form>
            </div>
          </div>
        </section>
      </main>
    );
  } else {
    return (
      <Route
        {...rest}
        render={(props) =>
          (!userLogin || checkExpried) ? <Component {...props} /> : <Redirect to="/home/main" />
        }
      />
    )
  }
}

export default PublicRoute
