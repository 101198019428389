import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
// import { changeEmail } from '../store/actions/userActions'
import { setCurrPage } from "../store/actions/postActions";
import CryptoJS from "crypto-js";
import logo from "../assets/imgs/logo.png";
import logom from "../assets/imgs/logom.png";
import loadingGif from "../assets/imgs/loading-gif.gif";
import styles from '../figma/RegistrationForm/RegistrationForm.module.css';

const Email = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const isDesktop = useMediaQuery({ minWidth: 992 });

    const [regis, setRegis] = useState(false);
    const [expried, setExpried] = useState(false);
    const [done, setDone] = useState(false);
    const [spinner, setSpinner] = useState(true);
   
    const decryptAES = (encryptedBase64) => {
        var key = process.env.REACT_APP_KEY;
        return CryptoJS.AES.decrypt(encryptedBase64, key).toString(
            CryptoJS.enc.Utf8
        );
    }

    const checkExpried = (dt) => {
        let current = new Date().getTime();
        let datetime = Number(dt);
        if (current - datetime > 24 * 60 * 60 * 1000) {
            setExpried(true);
            setSpinner(false)
        }
    }

    useEffect(() => {
        dispatch(setCurrPage("emailpage"));
        // if (location.state && location.state.from === "emailchange") {
        //     let newEmail = decryptAES(location.state.em1);
        //     let oldEmail = decryptAES(location.state.em2);
        //     let dt = decryptAES(location.state.dt);
        //     checkExpried(dt)
        //     if (!expried && newEmail !== oldEmail) {
        //         dispatch(changeEmail({ newEmail: newEmail, oldEmail: oldEmail })).then((res) => {
        //             if (res && res.status === 200) {
        //                 setSpinner(false);
        //                 history.push("signin")
        //             }
        //             if (res && res.status === 403) {
        //                 setRegis(true)
        //                 setSpinner(false)
        //             }
        //         });
        //     }
        // }
    }, [dispatch, location]);
  
    if (!spinner && regis) {
        return (
            <main className="loginPage">
                <section className="background">
                    <div className="loginContainer">
                        <div className="formWrapper">
                            <div className={styles.formContent}>
                                <img src={isDesktop ? logo : logom} alt="serviceLogo" className={isDesktop ? styles.serviceLogo : styles.serviceLogom} onClick={(ev) => { ev.preventDefault(); history.push("/"); }} />
                                <div className={styles.instructions3}>メールアドレスが変更されました</div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        );
    } else if (!spinner && expried) {
        return (
            <main className="loginPage">
                <section className="background">
                    <div className="loginContainer">
                        <div className="formWrapper">
                            <div className={styles.formContent}>
                                <img src={isDesktop ? logo : logom} alt="serviceLogo" className={isDesktop ? styles.serviceLogo : styles.serviceLogom} onClick={(ev) => { ev.preventDefault(); history.push("/"); }} />
                                <div className={styles.instructions3}>URLの有効期限が切れました</div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        );
    } else if (!spinner && done) {
        return (
            <main className="loginPage">
                <section className="background">
                    <div className="loginContainer">
                        <div className="formWrapper">
                            <div className={styles.formContent}>
                                <img src={isDesktop ? logo : logom} alt="serviceLogo" className={isDesktop ? styles.serviceLogo : styles.serviceLogom} onClick={(ev) => { ev.preventDefault(); history.push("/"); }} />
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        );
    } else {
        return (
            <main className="loginPage">
                <section className="background">
                    <div className="loginContainer">
                        <div className="formWrapper">
                            <form className={styles.formContent}>
                                {spinner && (
                                    <span className={styles.gifContainer}>
                                        <img className={styles.loadingGif} src={loadingGif} alt="ok" />
                                    </span>
                                )}
                            </form>
                        </div>
                    </div>
                </section>
            </main>
        );
    }
}

export default Email;