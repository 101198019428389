import './assets/scss/global.scss'

import React, { useState, useEffect } from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom'

import { Home } from './pages/Home';
import { Signin } from './pages/Signin';
import { Signout } from './pages/Signout';
import Email from './pages/Email';
import Signup from './figma/UserRegistration/UserRegistrationForm';
import Register from './figma/RegistrationForm/RegistrationForm';
import Forgot from './figma/PasswordReset/PasswordReset';

import PublicRoute from './cmps/PublicRoute'
// import weblogo from './assets/imgs/weblogo.png'

import SplashScreen from './SplashScreen';
import { userService } from './services/user/userService'


const App = () => {
  const [loading, setLoading] = useState(true);
  const [promptEvent, setPromptEvent] = useState(null);
  const [appAccepted, setAppAccepted] = useState(false);
  const isAuthenticated = userService.getLoginUser();
  let isAppInstalled = false;

  if (window.matchMedia('(display-mode: standalone)').matches || appAccepted) {
    isAppInstalled = true;
  }

  window.addEventListener('beforeinstallprompt', e => {
    e.preventDefault();
    setPromptEvent(e);
  });

  const installApp = () => {
    promptEvent.prompt();
    promptEvent.userChoice.then(result => {
      if (result.outcome === 'accepted') {
        setAppAccepted(true);
        console.log('User accepted the A2HS prompt');
      } else {
        console.log('User dismissed the A2HS prompt');
      }
    });
  };

  useEffect(() => {
    if (isAuthenticated === null) {
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    } else if (isAuthenticated && isAuthenticated.logged) {
      setLoading(false);
    }
  }, []);

  if (loading) {
    return (<SplashScreen />)
    // } else if (promptEvent && !isAppInstalled) {
    //   return (
    //     <div className="sign-in-page">
    //       <div className="div">
    //         <div className="splash-container">
    //           <img src={weblogo} alt="App Logo" className="splash-image" />
    //           <button onClick={installApp}>ホーム画面に追加</button>
    //         </div>
    //       </div>
    //     </div>
    //   );
  }

  return (
    <Router>
      <Switch>
        <PublicRoute path="/forgot" component={Forgot} />
        <PublicRoute path="/signin" component={Signin} />
        <PublicRoute path="/register" component={Register} />
        <PublicRoute path="/signup" component={Signup} />
        <PublicRoute path="/email" component={Email} />
        <PublicRoute path="/emailchange" component={Email} />
        <PublicRoute path="/signout" component={Signout} />
        <Route path="/" component={Home} />
      </Switch>
    </Router>
  )
}

export default App
