export function setCurrPage(page) {
  return async (dispatch) => {
    try {
      dispatch({ type: 'SET_CURR_PAGE', page })
      return page;
    } catch (err) {
      console.log('err:', err)
      return {
        status: 500,
        message: err
      };
    }
  }
}

export function setNextPage(number) {
  return async (dispatch) => {
    dispatch({ type: 'SET_NEXT_PAGE', page: number })
  }
}
