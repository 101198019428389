import React, { useState } from 'react';
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
import { MenuModal } from '../cmps/modal/MenuModal';
import logom1 from "../assets/imgs/logom1.png";
import leftAlign from "../assets/imgs/left-align.png";

export default function Topbar({ showSidebar }) {
  const history = useHistory();
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const [isShowMenu, setIsShowMenu] = useState(false);
  
  const toggleShowMenu = () => {
    setIsShowMenu((prev) => !prev);
  };

  return (
    <div className="topbar">
      <div className="topbarContainer">
        <div className="leftTopbar">
          {!isDesktop && <img src={leftAlign} alt="leftAlign" className="title-icon" onClick={toggleShowMenu} />}
          <img src={logom1} alt="serviceLogo" className="logo" onClick={(ev) => { ev.preventDefault(); history.push("/home/main") }} />
        </div>
        {!isDesktop && isShowMenu && <MenuModal toggleShowMenu={toggleShowMenu} />}
      </div>
    </div>
  );
}
