import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { ReactComponent as Menuicon } from "../../assets/imgs/menuicon.svg";
import { LogoutModal } from "../../cmps/modal/LogoutModal";
import { AlertModal } from "../../cmps/modal/AlertModal";
import { getParticipant } from "../../store/actions/userActions";
import { utilService } from "../../services/utilService";
import cross from "../../assets/imgs/cross.png";

const menuItems = [
  { label: "講義", minf: "（こうぎ）", link: "/home/table" },
  { label: "教科書", minf: "（きょうかしょ）", link: "/home/textbook" },
  { label: "補助教材", minf: "（ほじょきょうざい）", link: "/home/supplementary" },
  { label: "テスト", minf: "", link: "/home/tests" },
  { label: "設定", minf: "（せってい）", link: "/home/setting" }
];

export function MenuModal({ toggleShowMenu }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const [isLogoutModal, setIsLogoutModal] = useState(false);
  const [isAlertModal, setIsAlertModal] = useState(false);

  const [loggedUser, setLoggedUser] = useState(null);

  const handleClose = (link) => {
    toggleShowMenu()
    history.push(link);
  };

  const toggleLogoutModal = () => {
    setIsLogoutModal((prev) => !prev);
  };

  const toggleAlertModal = () => {
    setIsAlertModal((prev) => !prev);
  };

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === 'Escape') {
        if (isLogoutModal === true) {
          setIsLogoutModal(false);
        }
        if (isAlertModal === true) {
          setIsAlertModal(false);
        }
      }
    };
    window.addEventListener('keydown', handleEsc);
    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [isAlertModal, isLogoutModal]);

  useEffect(() => {
    dispatch(getParticipant()).then((user) => {
      if (user !== null && user.status === 200) {
        let participant = user.data.participant
        setLoggedUser(participant[0]);
      }
    });
  }, [dispatch]);

  return (
    <section className="mtearms">
      <div className="mbg" onClick={toggleShowMenu}></div>
      <div className="mcontainer">
        <div className="mtitle">
          <img onClick={toggleShowMenu} src={cross} className="micon" />
        </div>

        <div className="menuContainer">
          {menuItems.map((item) => (
            <div key={utilService.uuidv4()}>
              <div className="menuItem" onClick={(ev) => {
                ev.preventDefault();
                handleClose(item.link);
              }}>
                <p>{item.label} <span className="minf">{item.minf}</span></p>
                <Menuicon className="menuIcon" />
              </div>
              <div className="menuDivider" />
            </div>
          ))}
          <div className="menuItem" onClick={toggleLogoutModal}>
            <div>ログアウト</div>
            <Menuicon className="menuIcon" />
          </div>
          <div className="menuDivider" />
        </div>
        {isLogoutModal && <LogoutModal toggleLogoutModal={toggleLogoutModal} />}
        {isAlertModal && <AlertModal toggleAlertModal={toggleAlertModal} />}

      </div >
    </section >
  )
}
