import { useDispatch, useSelector } from 'react-redux';
import { lazy, Suspense, useEffect, useState, useRef } from "react";
import { Switch, useHistory } from 'react-router-dom'
import { Offline } from "react-detect-offline";
import { userService } from '../services/user/userService'
import { useMediaQuery } from "react-responsive";
import { CSSTransition } from "react-transition-group";
import { logout, updLoged, getUserByEmail } from '../store/actions/userActions'
import moment from 'moment-timezone'

import PrivateRoute from "../cmps/PrivateRoute";
import Topbar from "../components/Topbar";
import Sidebar from "../components/Sidebar";

const Setting = lazy(() => import("./Setting"));
const Lecture = lazy(() => import("./Lecture"));
const Tests = lazy(() => import("./Tests"));
const Textbook = lazy(() => import("./Textbook"));
const Supplementary = lazy(() => import("./Supplementary"));

const TestCard = lazy(() => import("../figma/TestDetail/TestCard"));
const LectureVideo = lazy(() => import("./../figma/LectureVideo/LectureVideo"));
const QuestionComponent = lazy(() => import("../figma/QuestionComponent/QuestionComponent"));
const SurveyComponent = lazy(() => import("./../figma/QuestionComponent/SurveyComponent"));
const NotFound = lazy(() => import("./Notfound"));

export function Home() {
  const dispatch = useDispatch()
  const history = useHistory();
  const nodeRef = useRef(null)
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const { currPage } = useSelector((state) => state.postModule);

  const userLogin = userService.getLoginUser();
  const checkExpried = userService.checkExpried(userLogin)
  const [hamburgerSidebar, setHamburgerSidebar] = useState(false);

  const showSidebar = () => {
    setHamburgerSidebar((prev) => !prev);
  };

  const extend = () => {
    if (!checkExpried && userLogin && userLogin.data) {
      dispatch(updLoged(
        userLogin.data.id, { "type": 1, "logged_at": new Date() }
      )).then((res) => {
        if (res) {
          userService.renew(1800);
        }
      });
    }
  };

  const extend2 = () => {
    let expDate = moment(new Date()).add(1800, 'seconds').format("YYYY-MM-DD HH:mm:ss")
    if (!checkExpried && userLogin && userLogin.data) {
      dispatch(updLoged(
        userLogin.data.id, { "type": 1, "logged_at": new Date(Date.parse(expDate)) }
      )).then((res) => {
        if (res) {
          userService.renew(3600)
        }
      });
    }
  }

  const getUserByEmaill = () => {
    if (!checkExpried && userLogin && userLogin.data) {
      dispatch(getUserByEmail(userLogin)).then((res2) => {
        if (res2 && res2.data && res2.data.is_login === 10) {
          dispatch(updLoged(
            userLogin.data.id, { "type": 1, "logged_at": null }
          )).then((res1) => {
            if (res1) {
              dispatch(logout()).then(() => {
                history.push("/signin");
              });
            }
          })
        }
      })
    }
  };

  if (currPage === "exam") {
    extend2();
  } else {
    extend();
  }

  getUserByEmaill();

  useEffect(() => {
    let interval = setInterval(() => {
      if (checkExpried) {
        history.push("/signin");
      }
    }, 2000);
    return () => clearInterval(interval);
  }, [history])

  useEffect(() => {
    if (!checkExpried && userLogin) {
      history.replace("/home/main");
    }
  }, [history])

  return (
    <div className="home-page">
      <div className="main">
        <Topbar showSidebar={showSidebar} />
        <div className="main-container">
          {isDesktop && !hamburgerSidebar && <Sidebar />}
          <CSSTransition nodeRef={nodeRef} in={hamburgerSidebar} timeout={200} classNames="sidebar" unmountOnExit>
            <Sidebar />
          </CSSTransition>
          <Suspense>
            <Switch>
              <PrivateRoute path="/home/main" component={Lecture} />
              <PrivateRoute path="/home/table" component={Lecture} />
              <PrivateRoute path="/home/lecturevideo" component={LectureVideo} />
              <PrivateRoute path="/home/tests" component={Tests} />
              <PrivateRoute path="/home/testcard" component={TestCard} />
              <PrivateRoute path="/home/question" component={QuestionComponent} />
              <PrivateRoute path="/home/textbook" component={Textbook} />
              <PrivateRoute path="/home/supplementary" component={Supplementary} />
              <PrivateRoute path="/home/setting" component={Setting} />
              <PrivateRoute path="/home/survey" component={SurveyComponent} />
              <PrivateRoute path="*/*" component={NotFound} />
            </Switch>
          </Suspense>
          <Offline>
            <div className="detect">
              <span>おっと、オフラインです</span>
            </div>
          </Offline>
        </div>
      </div>
    </div>
  );
}
