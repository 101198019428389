import { useState } from 'react';
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { updLoged, logout, getLoginUser } from '../../store/actions/userActions'
import loadingGif from "../../assets/imgs/loading-circle.gif";

export function ExpriedModal({ toggleExpriedModal }) {
  const dispatch = useDispatch()
  const history = useHistory()
  const [spinner, setSpinner] = useState(false);

  const doLogout = async () => {
    setSpinner(true)
    dispatch(getLoginUser()).then((user) => {
      if (user !== null && user.status === 200) {
        dispatch(updLoged(
          user.data.id, { "type": 2, "is_login": 0, "logged_at": null }
        )).then((res) => {
          if (res) {
            dispatch(logout());
            setSpinner(false)
            toggleExpriedModal();
            history.push(`/signin`);
          }
        });
      }
    });
  };

  return (
    <section className="tearms">
      <div className="bg" onClick={toggleExpriedModal}></div>
      <div className="container-paving">
        <div className="form-paving">
          <form className="div-7"
            onSubmit={(ev) => {
              ev.preventDefault();
              doLogout();
            }}
          >
            <div className="div-8"><p>{process.env.REACT_APP_EXPRIED} 分が経過しました。再度ログインしてください。</p></div>
            <button className="div-91">{!spinner && <p>閉じる</p>}{spinner && <img src={loadingGif} alt="loadingGif" className="loadingGif" />}</button>
          </form>
        </div>
      </div>
    </section>
  );
}
