import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import { userService } from '../services/user/userService'
import { clearStorageAct } from '../store/actions/userActions'

function PrivateRoute({ component: Component, ...rest }) {
  const dispatch = useDispatch()
  const userLogin = userService.getLoginUser();
  const checkExpried = userService.checkExpried(userLogin);
  const { currPage } = useSelector((state) => state.postModule);

  useEffect(() => {
    if (currPage !== "exam") {
      dispatch(clearStorageAct("question"));
    }
  }, [dispatch, currPage]);

  return (
    <Route
      {...rest}
      render={(props) =>
        (userLogin && !checkExpried) ? <Component {...props} /> : <Redirect to="/signin" />
      }
    />
  )
}

export default PrivateRoute
