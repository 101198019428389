import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { signup, sendToEmail, getCreatedinUser } from "../../store/actions/userActions";
import { Link, useHistory, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { toastService } from "../../services/toastService";
import { useMediaQuery } from "react-responsive";
import { nationData1 } from "../../test/Nation1"
import { addressData } from "../../test/Address"

import CryptoJS from "crypto-js";
import logo from "../../assets/imgs/logo.png";
import logom from "../../assets/imgs/logom.png";
import styles from './UserRegistrationForm.module.css';

function UserRegistrationForm() {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const isDesktop = useMediaQuery({ minWidth: 992 });

  const { createdInUser } = useSelector((state) => state.userModule);

  const [regis, setRegis] = useState(false);
  const [expried, setExpried] = useState(false);
  const [done, setDone] = useState(false);
  const [email, setEmail] = useState('');

  const reset = () => {
    setExpried(false)
    setRegis(false)
    setDone(false)
  };

  const [cred, setCred] = useState({
    email: "",
    name: "",
    password: "",
    repassword: "",
    nation_id: 1,
    address_id: 1,
    belong_to_flag: 2,
    belong_to_group: "",
    is_deactivated: 0
  });

  const [filed, setFiled] = useState(0);
  const handleFiled = (event) => {
    const field = event.target.name;     
    // if (event.target.value == 0) {
    //   toastService.notifyError("法人又は個人を選択してください");
    // }
    setFiled(event.target.value);
    setCred((prevCred) => ({ ...prevCred, [field]: event.target.value }));    
  };

  const handleChange = async ({ target }) => {
    const field = target.name;
    let value = target.type === "number" ? +target.value || "" : target.value;
    setCred((prevCred) => ({ ...prevCred, [field]: value }));
  };


  const doSubmit = async () => {
    if (filed == 0) {
      toastService.notifyError("法人又は個人を選択してください");
      return;
    }
    reset()
    let em = decryptAES(
      location?.search.split("?email=")[1].split("&datetime=")[0]
    ); //get_email
    let dt = decryptAES(location?.search.split("&datetime=")[1]); // get_datetime
    if (em !== "" && dt !== "") {
      checkExpried(em);
    } else {
      return;
    }
    dispatch(signup(cred)).then((user1) => {
      if (user1 && user1.status === 200) {
        dispatch(sendToEmail({ to: em }, 1)).then((res) => {
          if (res.status === 200) {
            setDone(true)
            toastService.notifySuccess("新規ユーザー登録が完了しました。");
          }
        });
      } else if (user1 && user1.status === 400) {
        toastService.notifyError("メールアドレスがすでに登録されえています");
      } else {
        toastService.notifyError("処理中にエラーが発生しました");
      }
    });
  };

  function decryptAES(encryptedBase64) {
    var key = process.env.REACT_APP_KEY;
    return CryptoJS.AES.decrypt(encryptedBase64, key).toString(
      CryptoJS.enc.Utf8
    );
  }

  function checkExpried(email) {
    cred.email = email;
    let current = new Date().getTime();
    let datetime = Number(decryptAES(location?.search.split("&datetime=")[1]));
    if (current - datetime > 24 * 60 * 60 * 1000) {
      setExpried(true);
    }
  }

  useEffect(() => {
    if (JSON.stringify(createdInUser) === null || createdInUser === null) {
      if (location.search === null || location.search === "") {
        history.push("/");
        return;
      }
      let em = decryptAES(
        location?.search.split("?email=")[1].split("&datetime=")[0]
      ); //get_email
      setEmail(em);
      let dt = decryptAES(location?.search.split("&datetime=")[1]); // get_datetime

      if (em === "" || dt === "") {
        history.push("/signin");
        return;
      }
      if (em !== "") {
        dispatch(sendToEmail({ to: em }, 0)).then((res) => {
          // console.log(res.data.length);
          if (res.data.length > 0) {
            setRegis(true);
          } else {
            checkExpried(em);
          }
        });
      }
    } else {
      let em = decryptAES(
        location?.search.split("?email=")[1].split("&datetime=")[0]
      ); //get_email
      setEmail(em);
      if (em !== "" && em != null) {
        dispatch(getCreatedinUser(em)).then((eme) => {
          if (eme) {
            console.log("eme >>> " + eme);
          }
        });
      }
    }
  }, [dispatch]);

  if (regis) {
    return (
      <main className="loginPage">
        <section className="background">
          <div className="loginContainer">
            <div className="formWrapper">
              <div className={styles.formContent}>
                <img src={isDesktop ? logo : logom} alt="serviceLogo" className={isDesktop ? styles.serviceLogo : styles.serviceLogom} onClick={(ev) => { ev.preventDefault(); history.push("/"); }} />
                <div className={styles.registrationTitle}>Email 登録済み</div>
              </div>
            </div>
          </div>
        </section>
      </main>
    );
  } else if (expried) {
    return (
      <main className="loginPage">
        <section className="background">
          <div className="loginContainer">
            <div className="formWrapper">
              <div className={styles.formContent}>
                <img src={isDesktop ? logo : logom} alt="serviceLogo" className={isDesktop ? styles.serviceLogo : styles.serviceLogom} onClick={(ev) => { ev.preventDefault(); history.push("/"); }} />
                <div className={styles.registrationTitle}>リンクの有効期限が切れました</div>
              </div>
            </div>
          </div>
        </section>
      </main>
    );
  } else if (JSON.stringify(createdInUser) === null || createdInUser === null) {
    return (
      <main className="loginPage">
        <ToastContainer />
        <section className="background">
          <div className="loginContainer">
            <div className="formWrapper" onSubmit={(ev) => { ev.preventDefault(); doSubmit(); }}>
              <form className={styles.formContent}>
                <img src={isDesktop ? logo : logom} alt="serviceLogo" className={isDesktop ? "serviceLogo" : "serviceLogom"} onClick={(ev) => { ev.preventDefault(); history.push("/"); }} />
                <h2 className={styles.registrationTitle}>会員情報登録</h2>
                <h2 className={styles.minf}>(じゅこうしゃじょうほうとうろく)</h2>

                <div className={styles.labelWrapper}>
                  <label className={styles.inputLabel}>メールアドレス</label>
                </div>
                <input
                  className={styles.inputField}
                  onChange={handleChange}
                  type="text"
                  id="email"
                  name="email"
                  value={email}
                  placeholder="メールアドレス"
                  disabled
                />

                <div className={styles.labelWrapper}>
                  <label className={styles.inputLabel}>個人 <span className={styles.minf1}>（こじん）</span> or 法人 <span className={styles.minf1}>（ほうじん）</span> {filed == 0 && <span style={{color: '#CB3030'}}>※</span>}</label>
                </div>
                <select
                  id="belong_to_flag"
                  name="belong_to_flag"
                  // value={cred.belong_to_flag}
                  className={styles.statusSelect}
                  onChange={handleFiled}
                >
                  <option value={0}></option>
                  <option value={1}>個人</option>
                  <option value={2}>法人</option>
                </select>

                {filed == 2 && <div className={styles.labelWrapper}>
                  <label className={styles.inputLabel}>申請者<span className={styles.minf1}>（しんせいしゃ）</span>の所属<span className={styles.minf1}>（しょぞく）</span></label>
                </div>}
                {filed == 2 && <input
                  className={styles.inputField}
                  onChange={handleChange}
                  type="text"
                  id="belong_to_group"
                  name="belong_to_group"
                  value={cred.belong_to_group}
                  placeholder="株式会社"
                />}

                <div className={styles.labelWrapper}>
                  <label className={styles.inputLabel}>出身国籍 <span className={styles.minf1}>（しゅっしんこくせき）</span></label>
                </div>
                <select
                  id="nation_id"
                  name="nation_id"
                  // value={cred.nation_id}
                  className={styles.statusSelect}
                  onChange={handleChange}
                >
                  {nationData1.map((item, index) => {
                    return (
                      <option key={index + 1} value={index + 1}>
                        {item}
                      </option>
                    );
                  })}
                </select>


                <div className={styles.labelWrapper}>
                  <label className={styles.inputLabel}>在日中 <span className={styles.minf1}>（ざいにちちゅう）</span> の住所 <span className={styles.minf1}>（じゅうしょ）</span></label>
                </div>
                <select
                  id="address_id"
                  name="address_id"
                  // value={cred.address_id}
                  className={styles.statusSelect}
                  onChange={handleChange}
                >
                  {addressData.map((item, index) => {
                    return (
                      <option key={index + 1} value={index + 1}>
                        {item}
                      </option>
                    );
                  })}
                </select>

                <div className={styles.divButton}>
                  <button  type="submit" className={styles.submitButtonActive}>
                    <p>受講者登録をする</p>
                    <p className={styles.minf1}>（じゅこうしゃとうろく）</p>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </section>
      </main>
    );
  } else if (done) {
    return (
      <main className="loginPage">
        <section className="background">
          <div className="loginContainer">
            <div className="formWrapper">
              <div className={styles.formContent}>
                <img src={isDesktop ? logo : logom} alt="serviceLogo" className={isDesktop ? styles.serviceLogo : styles.serviceLogom} onClick={(ev) => { ev.preventDefault(); history.push("/"); }} />
                <h2 className={styles.registrationTitle}>受講者情報登録</h2>
                <h2 className={styles.minf}>（じゅこうしゃじょうほうとうろく）</h2>
                <br /><br />
                <p className={styles.message}>
                  この度 <span className={styles.minf1}>（たび）</span> は「日本の農業をまなぼう」アプリを<br />
                  ご利用 <span className={styles.minf1}>（りよう）</span> いただきありがとうございます。<br />
                  <br /><br />
                  受講者情報 <span className={styles.minf1}>（じゅこうしゃじょうほう）</span> の登録 <span className={styles.minf1}>（とうろく）</span> が完了 <span className={styles.minf1}>（かんりょう）</span> しました。<br />
                  <br />
                  ログイン用 <span className={styles.minf1}>（よう）</span> のpasswordが送信 <span className={styles.minf1}>（そうしん）</span> されますのでご確認 <span className={styles.minf1}>（かくにん）</span> いただき「日本の農業をまなぼう」をご利用 <span className={styles.minf1}>（りよう）</span> ください。<br />
                </p>
                <div className={styles.divButton}>
                  <Link to="/login"><button className={styles.submitButtonActive}>ログインへ戻<span className={styles.minf1}>（もど）</span>る</button></Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    );
  }
}

export default UserRegistrationForm;