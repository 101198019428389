import { useState } from 'react';
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { updLoged, logout, getLoginUser } from '../../store/actions/userActions'
import loadingGif from "../../assets/imgs/loading-circle.gif";

export function LogoutModal({ toggleLogoutModal }) {
  const dispatch = useDispatch()
  const history = useHistory()
  const [spinner, setSpinner] = useState(false);

  const doLogout = async () => {
    setSpinner(true)
    dispatch(getLoginUser()).then((user) => {
      if (user !== null && user.status === 200) {
        dispatch(updLoged(
          user.data.id, { "type" : 1, "is_login": 0, "logged_at": null }
        )).then((res) => {
          if (res !== null) {
            setSpinner(false)
            dispatch(logout()).then((res) => {
              if (res !== null) {
                history.push(`/signin`);
              }
            });
          }
        });
      }
    });
  };

  return (
    <section className="tearms">
      <div className="bg" onClick={toggleLogoutModal}></div>
      <div className="container-paving">
        <div className="form-paving">
          <div className="div-7">
            <div className="div-8"><p>ログアウトしますか？</p></div>
            <div className="div-91">{!spinner && <p onClick={doLogout}>ログアウト</p>}{spinner && <img src={loadingGif} alt="loadingGif" className="loadingGif" />}</div>
            <div onClick={toggleLogoutModal} className="div-92"><p>キャンセル</p></div>
          </div>
        </div>
      </div>
    </section>
  );
}
