import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import { Link, useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { toastService } from '../services/toastService';
import { userService } from '../services/user/userService'
import { utilService } from "../services/utilService";

import { IconButton, InputAdornment } from '@mui/material';
import { useMediaQuery } from "react-responsive";
import { ExpriedModal } from "../cmps/modal/ExpriedModal";
import { updLoged, login } from '../store/actions/userActions'
import { setCurrPage } from "../store/actions/postActions";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import warning from "../assets/imgs/warning.png";
import logo from "../assets/imgs/logo.png";
import logom from "../assets/imgs/logom.png";

export const Signin = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [cred, setCred] = useState({
    email: "",
    password: "",
    showPassword: false,
  });
  const [msgEmail, setMsgEmail] = useState("");
  const [msgPass, setMsgPass] = useState("");
  const [msg, setMsg] = useState("");
  const [msg1, setMsg1] = useState("");
  const [msg2, setMsg2] = useState("");
  const [msg3, setMsg3] = useState("");
  const [msg4, setMsg4] = useState("");
  const [instruct, setInstruct] = useState("");

  const isDesktop = useMediaQuery({ minWidth: 992 });

  const storageUser = userService.getLoginUser();
  const checkExpried = userService.checkExpried(storageUser)

  const [isExpriedModal, setIsExpriedModal] = useState(false);
  const toggleExpriedModal = () => {
    setIsExpriedModal((prev) => !prev);
  };

  const handleClickShowPassword = () => {
    setCred({
      ...cred,
      showPassword: !cred.showPassword,
    });
  };
  const showMsgEmail = (txt) => {
    setMsgEmail(txt);
    // setTimeout(() => setMsgEmail(""), 3000);
  };

  const showMsgPass = (txt) => {
    setMsgPass(txt);
    // setTimeout(() => setMsgPass(""), 3000);
  };

  const showMsg = (txt) => {
    setMsg(txt);
  };
  const showMsg1 = (txt) => {
    setMsg1(txt);
  };
  const showMsg2 = (txt) => {
    setMsg2(txt);
  };
  const showMsg3 = (txt) => {
    setMsg3(txt);
  };
  const showMsg4 = (txt) => {
    setMsg4(txt);
  };
  const handleEmailChange = async ({ target }) => {
    const field = target.name;
    let value = target.type === "number" ? +target.value || "" : target.value;
    setCred((prevCred) => ({ ...prevCred, [field]: value }));
    showMsgEmail('')
  };

  const handlePassChange = async ({ target }) => {
    const field = target.name;
    let value = target.type === "number" ? +target.value || "" : target.value;
    setCred((prevCred) => ({ ...prevCred, [field]: value }));
    showMsgPass('')
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const doLogin = async () => {
    if (cred.email !== "" && cred.password !== "") {
      if (!/.+@.+/.test(cred.email)) {
        showMsgEmail('メールアドレスが無効です')
      }
      dispatch(login(cred)).then((user) => {
        if (user !== null && user.status === 406) {
          toastService.notifyError('システムはメンテナンス中です');
          history.push("/signin");
        } else if (user !== null && user.status === 404) {
          toastService.notifyError('こちらのアカウントはすでにログイン中です');
          history.push("/signin");
        } else if (user !== null && user.status === 405) {
          toastService.notifyError('このアカウントは退会されています。');
          history.push("/signin");
        } else if (user !== null && (user.status === 400)) {
          showMsg("入力した情報")
          showMsg1('（じょうほう）')
          showMsg2('に誤')
          showMsg3('（あやま）')
          showMsg4('りがあります')
        } else if (user !== null && user.status === 200) {
          dispatch(updLoged(
            user.data.id, { "type": 1, "is_login": 1, "logged_at": new Date() }
          )).then((res) => {
            if (res !== null && res.status === 200) {
              history.push("/home/main");
            }
          });
        }
      });
    } else {
      if (cred.email === "") {
        showMsgEmail('メールアドレスを入力してください')
      }
      if (cred.password === "") {
        showMsgPass('パスワードを入力してください')
        showMsg('')
        showMsg1('')
        showMsg2('')
        showMsg3('')
        showMsg4('')
      }
    }
  };

  useEffect(() => {
    dispatch(setCurrPage("signin"));
    if (!isExpriedModal && instruct === "") {
      try {
        fetch(process.env.REACT_APP_ELURL)
          .then(res => {
            return res.text()
          })
          .then(data => {
            if (!data.includes('AccessDenied')) {
              setInstruct(data.split('\r\n'))
            }
          })
      } catch (e) {
        setInstruct("")
      }
    }
  }, [])

  return (
    <main className="loginPage">
      <ToastContainer />
      <section className="background">
        <div className="loginContainer">
          {checkExpried && <ExpriedModal toggleExpriedModal={toggleExpriedModal} />}

          <form className="formWrapper"
            onSubmit={(ev) => {
              ev.preventDefault();
              doLogin();
            }}
          >
            <img src={isDesktop ? logo : logom} alt="serviceLogo" className={isDesktop ? "serviceLogo" : "serviceLogom1"} />
            <label className="inputLabel">ログインID</label>
            <input
              id="email"
              name="email"
              type="text"
              onChange={handleEmailChange}
              value={cred.email}
              className="inputField"
              placeholder="メールアドレス"
              aria-label="メールアドレス"
              autoComplete="username"
            />
            <div className="msg">
              {msgEmail !== '' && <img src={warning} alt="xxx" className="img-msg" />}
              <div className="div-msg">{msgEmail}</div>
            </div>
            <label className="inputLabel">パスワード</label>

            <input
              id="password"
              name="password"
              type={
                cred.showPassword
                  ? "text"
                  : "password"
              }
              onChange={handlePassChange}
              value={cred.password}
              className="inputField"
              placeholder="パスワード"
              aria-label="パスワード"
              autoComplete="current-password"
            />
            <InputAdornment className="yeaIcon" position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
                size="small"
              >
                {cred.showPassword ? (
                  <VisibilityOff fontSize="inherit" />
                ) : (
                  <Visibility fontSize="inherit" />
                )}
              </IconButton>
            </InputAdornment>

            <div className="msg1">
              {(msg !== '' || msgPass !== '') && <img src={warning} alt="xxx" className="img-msg1" />}
              {msg !== '' && <div className="div-msg1">{msg}<p className="mini">{msg1}</p>{msg2}<p className="mini">{msg3}</p>{msg4}</div>}
              {msgPass !== '' && msg === '' && <div className="div-msg1">{msgPass}</div>}
            </div>

            <p className="forgotPassword">
              パスワードをお忘<span className="mini">（わす）</span>れの方<span className="mini">（かた）</span>は <Link to="/forgot">
                <span
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  こちら
                </span>
              </Link>
            </p>

            <button className="loginButton">ログイン</button>
            <Link to="/register"> <p className="registerButton">受講者登録<span className="mini">（じゅこうしゃとうろく）</span>はこちら</p> </Link>
            {instruct.length > 0 && <div className="note">
              {
                instruct.map((row, index) => (
                  <div key={utilService.uuidv4()}>{index <= instruct.length - 6 && row.trim().replace("［", "[").replace("］", "]")}</div>
                ))
              }
              <br></br>
              {
                instruct.map((row, index) => (
                  <div key={utilService.uuidv4()}>{index >= instruct.length - 5 && index < instruct.length - 3 && row.trim().replace("［", "[").replace("］", "]")}</div>
                ))
              }
              <br></br>
              {
                instruct.map((row, index) => (
                  <div key={utilService.uuidv4()}>{index >= instruct.length - 2 && index < instruct.length && row.trim().replace("［", "[").replace("］", "]")}</div>
                ))
              }
            </div>}
          </form>
        </div>
      </section>
    </main>
  );

};
